body {
    background-image: url("./images/Addventure-enrollment-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.addLogo{
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
}

.card{
    margin-top: 30px;
    max-width: 33%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.firstCard {
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.formCard{
    margin-top: 30px;
    max-width: 66%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.lastCard{
    margin-top: 30px;
    max-width: 66%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.orangeText{
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    color: #f36c21;
    font-weight: 700;
    padding-top: 30px;
    font-size: 20px;
    letter-spacing: 3px;
    word-spacing: 7px;
}

.blackText {
    text-align: center;
    color: #000;
    font-weight: 500;
    padding-top: 30px;
    font-size: 18px;
    word-spacing: 7px;
}

.supportLink {
    text-align: center;
    display: block;
    font-weight: 300;
    font-size: 15px;
    word-spacing: 3px;
    padding-left: 5px;
    padding-right: 5px;
}

.blackTextSmall {
    text-align: center;
    color: #000;
    font-weight: 300;
    padding-top: 30px;
    font-size: 15px;
    word-spacing: 3px;
    padding-left: 40px;
    padding-right: 40px;
}

.errorText {
    text-align: center;
    color: #f36c21;
    font-weight: 600;
    padding-bottom: 30px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.submitButton {
    padding-top: 20px;
}

.dataForm {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
}
